<template>
    <div class="MySteps">
        <div v-for="(item, index) in items"
             :key="index"
             :class="['MySteps__item', {'finish': current > index, 'process': current === index, 'wait': current < index}]"
             @click="onActive(index)"
        >
            <div class="MySteps__icon">
                <my-badge>
                    <template v-if="item.dot" #content>
                        <van-icon class-prefix="ri" color="#ee0a24" name="error-warning-fill" size="13" style="display: block;" />
                    </template>
                    <i :class="['MySteps__icon-content', {'iconfont icon-dui': current === index}]"></i>
                </my-badge>
            </div>
            <div class="MySteps__text">{{ item.text }}</div>
        </div>
    </div>
</template>

<script>
    import MyBadge from "../badge";

    export default {
        name: "MySteps",
        components: {MyBadge},
        props: {
            items: {
                type: Array,
                required: true
            },
            current: Number,
            beforeLeave: Function,
        },
        methods: {
            onActive(index) {
                if (index === this.current) return;
                if (this.beforeLeave) {
                    const before = this.beforeLeave();
                    if (before && before.then) {
                        before.then(() => {
                            this.$emit('update:current', index);
                        }).catch(() => {});
                    } else if (before) {
                        this.$emit('update:current', index);
                    }
                } else {
                    this.$emit('update:current', index);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .MySteps {
        display: flex;
        align-items: center;
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            color: #969799;
            flex: 1;
            overflow: hidden;
            cursor: pointer;
            padding: 2px 0;
            &:last-child {
                //flex: unset;
                //width: fit-content;
                .MySteps__icon:after {
                    display: none;
                }
            }
            &:first-child {
                //flex: unset;
                //width: fit-content;
                .MySteps__icon:before {
                    display: none;
                }
            }
            &.process {
                .MySteps__icon {
                    &-content {
                        width: 16px;
                        height: 16px;
                        font-size: 16px;
                        color: #1677FF;
                        background: #FFFFFF;
                    }
                    &:after {
                        left: 100%;
                    }
                    &:before {
                        right: 100%;
                    }
                }
                .MySteps__text {
                    color: #1677FF;
                }
            }
        }
        &__icon {
            height: 16px;
            width: 16px;
            text-align: center;
            margin: 4px 0;
            position: relative;
            &-content {
                display: inline-block;
                height: 8px;
                width: 8px;
                border-radius: 8px;
                background: #969799;
            }
            &:after, &:before {
                content: '';
                position: absolute;
                width: 9999px;
                height: 1px;
                background: #EBEDF0;
                top: 8px;
            }
            &:after {
                left: calc(100% - 4px);
            }
            &:before {
                right: calc(100% - 4px);
            }
        }
        &__text {
            line-height: 20px;
        }
    }
</style>
