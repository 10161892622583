<script>
    import {Badge} from "vant";

    export default {
        name: "MyBadge",
        extends: Badge
    }
</script>

<style lang="scss" scoped>
    .van-badge {
        min-width: 0;
        padding: 0;
        border: none;
        line-height: 1;
        background: none;
    }
</style>
